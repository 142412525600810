import React, {useState, useEffect} from 'react';
import { AutoCenter } from 'antd-mobile'
import sendFetch from '../../utils/fetch';

const PaymentNum = (props) => {
    const [payNum ,setPayNum] = useState(0);
    
    useEffect (()=> {
        sendFetch('signUp/getSignUpContent', {}, 'get')
        .then(data => {
            if(data.code === 1 && data.data._id) {
                setPayNum(data.data.paymentNum)
            }
        })
    }, [])

    const thousands = (num) => {
        let str = num.toString();
        let reg = str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
        return str.replace(reg,"$1,");
    }

    return (
        <>
            {
                payNum === 0 ? null : <div style={{ padding:'20px 20px 0px'}}>
                    <AutoCenter style={{ fontSize:16, color:'#333', fontWeight:700, marginBottom:15 }}>系统核算报名费</AutoCenter>
                    <AutoCenter style={{ fontSize:36, color:'#F79646', fontWeight: 900 }} >￥{thousands(payNum)}</AutoCenter>
                </div>
            }
        </>
    )
}



export default PaymentNum;