

import "whatwg-fetch";
// import {notification} from 'antd';
import {browserHistory} from 'react-router';
let options = {};

/**
 * 对于请求的封装；
 *
 * @param {String} api 外部api需要以(http或者https)协议开头
 * @param {Object} params 可以是普通对象，可以是formData
 * @param {String} type 'post','get'两种
 * @param {String} cookie 且只能为'exclude'
 */

const sendFetch = (api, params, type, cookie='include') => {
    // 参数类型判断；
    if(typeof api !== 'string') {
        throw Error('api参数应为字符串')
    }
    if(typeof params !== 'object') {
        throw Error('params参数应为对象')
    }
    if(typeof type !== 'string') {
        throw Error('type参数应为字符串')
    }

    let apiUrl = `/api/${api}`;
    if(window.location.hostname == 'localhost') {
        apiUrl = `http://localhost:3001/api/${api}`;
    }
    
    // cookie为exclude将不携带cookie；
    if(cookie === 'include') {
        options = {credentials:'include'}
    }
    if(type === 'post' && Object.prototype.toString.call(params) === '[object FormData]') {
        // fromData的post请求；
        return POST_FORMDATA (apiUrl, params);
    };
    if(type === 'post' && Object.prototype.toString.call(params) === '[object Object]') {
        // json的post请求；
        return POST(apiUrl, params);
    }
    if(type === 'get') {
        // get请求
        apiUrl = apiUrl.split('?')[0];
        return GET(apiUrl, {...params});
    }
}

const GET = (apiUrl, params) => {
    let paramsArray = [];
    Object.keys(params).forEach(key => paramsArray.push(key + '=' + encodeURIComponent(params[key])))
    if(apiUrl.search(/\?/)===-1) {
        apiUrl += '?' + paramsArray.join('&')
    } else {
        apiUrl += '&' + paramsArray.join('&')
    }

    return sendRequest(apiUrl, {...options})
}

const POST = (apiUrl, params) => sendRequest(apiUrl, {
    method:'POST',
    body:JSON.stringify(params),
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    ...options
});


const POST_FORMDATA = (apiUrl, params) => sendRequest(apiUrl, {
    method:'POST',
    body:params,
    ...options
});




const sendRequest = (apiUrl, options) => new Promise ((resolve, reject)=> {
    // 顶部loading
    // NProgress.start();

    fetch(apiUrl, options)
    .then((response) => {
        if(response.ok) {
            return response.json();
        } else {
            if(response.status === 404) {
                console.log(response);
            }
            if(response.status >= 500){
                
                alert('服务错误，CODE:500')
            }
        }
    })
    .then((data) => {
        if(data.code === -100) {
            // 失去登录态，或者没登录
            let path = window.location.pathname;
            if(path === '/login') return;
            browserHistory.replace({pathname:'/login', query:{path}})
        } else {
            resolve(data);
        }
    })
    .catch(function (err) {
        reject();
        alert('服务端错误，稍后重试！')
        // NProgress.done();
    })
})




export default sendFetch;