import React, {useState, useEffect} from 'react';
import { Layout, Statistic } from 'antd';
import Navbar from 'rsuite/Navbar';
import Nav from 'rsuite/Nav';
import {browserHistory} from 'react-router';
import './index.less';
import 'rsuite/dist/rsuite.min.css';
import {navBarArr} from '../../store/pc/mockData_pc.js'
import top_pc from '../../assets/top_pc.png'

const { Countdown } = Statistic;

const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30;


const MainLayoutPc = (props) => {
    const [activeKey, setActiveKey] = useState('1')

    useEffect (()=> {
        // 获取路由，路由参数当做eventKey
        if(props.location.pathname === '/') {
            setActiveKey('1')
        } else if(props.params.id){
            setActiveKey(props.params.id)
        }
    }, [])

    const onSelect = (key) => {
        setActiveKey(key)
        // 跳转路由
        if(key == '1') {
            browserHistory.push('/');
            return;
        }
        browserHistory.push(`/${key}`)
    }
    return (
        <Layout className="layout">
            <img style={{ width:'100%', height:115 }} src={top_pc} />
            <Navbar className='navbar_pc' appearance="inverse">
            <Nav onSelect={onSelect} activeKey={activeKey}>
                <Nav.Item key="1" eventKey="1" >主页</Nav.Item>
                {
                    navBarArr.map(item => <Nav.Item key={item.key} eventKey={item.key}>{item.label}</Nav.Item>)
                }
            </Nav>
            </Navbar>
            <div className='content_pc' >
                <div className='children_pc'>{props.children}</div>
                <div className='rightBar_pc'>
                    <div className='text_rightbar_pc'>
                        距离会议开始还剩
                    </div>
                    <Countdown title="Day Level" value={deadline} format="D 天 H 时 m 分 s 秒" />

                </div>
            </div>
        </Layout>
    )
}



export default MainLayoutPc;