import React from 'react';
import ReactDOM from 'react-dom';
import {browserHistory as history, Router, Route} from 'react-router'
import MainLayoutPc from './components/mainLayoutPc/index';
import MainLayoutMob from './components/mainLayoutMob/index';

const loadRoute = (cb) => (module) => cb(null, module.default);
let mob = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

// 这次不用双端，只用移动端；

const Root = () => {
    return (
        <div>
            <Router history={history} >
                <Route path='/' getComponent={
                    (location, cb) => {
                        import(/* webpackChunkName: 'indexMob' */'./pages/mobPage/index/index.js').then(loadRoute(cb));
                    }
                } />
                <Route path='/editor113' getComponent={
                    (location, cb) => {
                        import(/* webpackChunkName: 'contentEditor' */'./pages/pcPage/contentEditor/index.js').then(loadRoute(cb));
                    }
                } />

                <Route path='/systermcangzhou03' getComponent={
                    (location, cb) => {
                        import(/* webpackChunkName: 'systerm' */'./pages/pcPage/systerm/index.js').then(loadRoute(cb));
                    }
                } />

                <Route path='/login' getComponent={
                    (location, cb) => {
                        import(/* webpackChunkName: 'login_mob' */'./pages/commonPage/login/index.js').then(loadRoute(cb));
                    }
                } />
                
                <Route path='/detail' component={MainLayoutMob} >
                    <Route path='signUp' getComponent={
                        (location, cb) => {
                            import(/* webpackChunkName: 'signUp' */'./pages/mobPage/signUp/index.js').then(loadRoute(cb));
                        }
                    } />
                    <Route path='applyBill' getComponent={
                        (location, cb) => {
                            import(/* webpackChunkName: 'applyBill' */'./pages/mobPage/applyBill/index.js').then(loadRoute(cb));
                        }
                    } />
                    <Route path='hotal' getComponent={
                        (location, cb) => {
                            import(/* webpackChunkName: 'hotal' */'./pages/mobPage/hotal/index.js').then(loadRoute(cb));
                        }
                    } />

                    <Route path=':id' getComponent={
                        (location, cb) => {
                            import(/* webpackChunkName: 'detailText_mob' */'./pages/mobPage/detailText/index.js').then(loadRoute(cb));
                        }
                    } />
                </Route>
            </Router>
        </div>
        
    )
}




ReactDOM.render(<Root />, document.getElementById('root'));