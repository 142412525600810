import React, {useState, useEffect} from 'react';
import { NavBar, Button, Image, ImageViewer } from 'antd-mobile'
import {browserHistory} from 'react-router';
import './index.less';
import {indexButtonArr} from '../../store/mob/mockData_mob.js'
import jiaotongImg1 from '../../assets/jiaotong1.jpg';
import jiaotongImg2 from '../../assets/jiaotong2.jpg';


import PaymentNum from '../paymentNum/index';

const MainLayoutMob = (props) => {

    const [visible, setVisible] = useState(false)
    console.log(props)
    const goBack = () => {
        browserHistory.go(-1)
    }

    const goBill = () => {
        browserHistory.replace('/detail/applyBill')
    }


    return (
        <div style={{ maxWidth:700, margin:'0 auto' }}>
            <NavBar onBack={goBack}>
            {
                indexButtonArr.map(item =>{
                    if(item.key === props.params.id) {
                        return item.label;
                    }
                })
            }
            {
                props.location.pathname === '/detail/signUp' ? '填写报名信息' : ''
            }
            {
                props.location.pathname === '/detail/applyBill' ? '申请发票' : ''
            }
            {
                props.location.pathname === '/detail/hotal' ? '酒店预定' : ''
            }
            {
                props.location.pathname === '/detail/hotalPayDetail' ? '酒店付款信息' : ''
            }
            </NavBar>
            {
                props.params.id === '118' ? <PaymentNum /> : null
            }
            <div>{props.children}</div>
            {
                props.params.id === '113' ? <div style={{ padding:'0 20px', marginBottom:100, overflow:'hidden' }} >
                    <Image
                        src={jiaotongImg1}
                        onClick={()=> {setVisible(true)}}
                        width="100%"
                        fit='cover'
                        style={{ borderRadius: 4, float:'left' }}
                    />
                    <Image
                        src={jiaotongImg2}
                        onClick={()=> {setVisible(true)}}
                        width="100%"
                        fit='cover'
                        style={{ borderRadius: 4, float:'left' }}
                    /></div> : null
            }
            {
                props.params.id === '118' ? <div style={{ padding:'0 25px', marginTop:25 }} ><Button onClick={goBill} block shape='rounded' color='primary'>申请发票</Button></div> : null
            }
            <ImageViewer
                image={jiaotongImg1}
                visible={visible}
                onClose={() => {
                    setVisible(false)
                }}
            />
            <ImageViewer
                image={jiaotongImg2}
                visible={visible}
                onClose={() => {
                    setVisible(false)
                }}
            />
        </div>
    )
}



export default MainLayoutMob;